export default {
  name: "EditKolfund",
  data() {
    return {
      form: {
        nominal: 0,
        mutationPosition: "",
        collectorFundingId: "",
        transactionCodeId: "",
        accountNumberPair: "",
      },
      options: {
        transactionCodeId: [],
        mutationPosition: [],
        collectorFunding: [],
      },
      property: {
        animation: {
          submitLoading: false,
        },
      },
      identity: {
        collectorFundingDetailId: "",
      },
    };
  },
  methods: {
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem(
        "COLLECTOR_FUNDING_DETAIL_ID_EDIT"
      );
      this.identity.collectorFundingDetailId = getTokenFromSession;
      this.getCollectorFundingById();
    },
    routeToPageList() {
      this.$router.push("/collector-funding-detail");
    },

    async getReferenceKolfund() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: "",
            chartOfAccountCode: "",
            officeId: "",
            page: 0,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.collectorFunding = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = index.userName;
            const value = index.collectorFundingId;
            this.options.collectorFunding.push({ text, value });
          });
        }
      } catch (error) {}
    },
    helperGetTransactionCodeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            name: "",
            page: 0,
            menuAccessid: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_MENU_ACCESS_COLLECTOR_FUNDING,
          },
          url: "transaction-code",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            const description = i.transactionName;
            const transactionCode = i.transactionCode;
            this.options.transactionCodeId.push({
              text,
              value,
              description,
              transactionCode,
            });
          });
        });
    },
    appendOptionsmutationPosition() {
      this.options.mutationPosition = [
        { value: "D", text: "Debet" },
        { value: "C", text: "Credit" },
      ];
    },
    async handleEditCollectorFunding() {
      var data = {
        nominal: this.form.nominal,
        mutationPosition: this.form.mutationPosition,
        collectorFundingId: this.form.collectorFundingId,
        transactionCodeId: this.form.transactionCodeId,
        accountNumberPair: this.form.accountNumberPair,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const confirm = await this.simpleConfirmation(
        "Edit Data Ini ?",
        "is-success"
      );
      if (!confirm) return;
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl:
            "collector-funding-detail/" +
            this.identity.collectorFundingDetailId,
          payload: data,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        await this.simpleWait(1500);
        this.property.animation.submitLoading = false;
      }
    },
    async getCollectorFundingById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "collector-funding-detail/" +
              this.identity.collectorFundingDetailId,
          });
          if (resp.data.code === "SUCCESS") {
            this.form.nominal = resp.data.data.nominal;
            this.form.mutationPosition = resp.data.data.mutationPosition;
            this.form.collectorFundingId = resp.data.data.collectorFundingId;
            this.form.transactionCodeId = resp.data.data.transactionCodeId;
            this.form.accountNumberPair = resp.data.data.accountNumberPair;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageList(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan`,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageList(),
          });
        }
      }
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.helperGetTransactionCodeDataForReference();
    this.appendOptionsmutationPosition();
    this.getReferenceKolfund();
  },
};
